<template>
  <el-card>
    <div class="user-profile">
    <el-avatar
      :src=userInfo.avatar
            />
            <p class="user-name">{{ userInfo.name }}</p>
            <div class="user-stats">
                  <div class="user-stat">
                    <p>貼文</p>
                    <p>{{ userInfo.postCount }}</p>
                  </div>
                  <div class="user-stat">
                    <p>追蹤者</p>
                    <p>{{ userInfo.followers }}</p>
                  </div>
                  <div class="user-stat">
                    <p>追蹤中</p>
                    <p>{{ userInfo.trackings }}</p>
                  </div>
                </div>
              </div>
              <div v-if="$route.params.Id != undefined  && $route.params.Id !== currentUser().id " class="mt-3">
                <el-button v-if="userInfo.isFollowing " type="primary" plain @click="unFollower()">追蹤中</el-button>
                <el-button  v-else type="info" plain @click="follower()">追蹤</el-button>
                
                <template v-if="userInfo.isFriend" >
                  <el-button   type="info" plain @click="deleteFriend()">解除好友</el-button>
                </template>
                <template v-else> 
                  <el-button v-if="!userInfo.isSendRequest"  type="info" plain @click="friendRequest()">加好友</el-button>
                  <el-button  v-else type="info" plain @click="unFriendRequest()">交友邀請已送出</el-button>
                </template>
              </div>

            </el-card>
            <el-card class="mt-3">
              <div class="user-profile">
                <h3 class="user-Introduction-title mb-2">簡介</h3>
                <p v-if='userInfo.introduction!=""' class="user-Introduction" v-html="userInfo.introduction">
                </p>
                <p class="text-start" v-else style="color:rgba(0,0,0,.4)">
                  簡介準備中 ~
                </p>
                <el-divider />
                <h3 class="user-Introduction-title  mb-2">學習履歷</h3>
                <p class="user-Introduction" v-if=" userInfo.resumeList.filter(x=>x.visible).length >0">
                  <ul class="list-group list-group-flush">
                    <li v-for="i in userInfo.resumeList.filter(x=>x.visible)" :key="i" class="list-group-item px-0 "  >
                      <p style="font-size: large;" class="my-1">{{ i.title }}</p>
                      <p style="font-size: small;color: #606266;">{{ i.content }}</p>
                    </li>
                  </ul>
                </p>
                <p  class="text-start" v-else style="color:rgba(0,0,0,.4)">
                  學習履歷準備中 ~
                </p>
                <el-button v-if=" $route.params.Id == undefined ||  $route.params.Id == currentUser().id" class="mt-2" type="info" plain @click="$router.push(`/ChargeMeTribe/EditInfo`)"
                  >編輯</el-button
                >
              </div>
            </el-card>
</template>

<script>
export default {
  name:"User",
  data(){
    return {
      userInfo:{
        avatar:"",
        name:'',
        postCount:0,
        followers:0,
        trackings:0,
        resumeList:[]

      }
    };
  },
  watch: {
    '$route.params.Id': function(newId, oldId) {
      console.log(newId, oldId)
      this.initData();
    }
  },
  methods:{
    initData(){
      let vm =this;
      let api = `${process.env.VUE_APP_URL_API}/Resume/info/${vm.$route.params.Id}`
      if(vm.$route.params.Id== undefined){
        api = `${process.env.VUE_APP_URL_API}/Resume/info`
      }
      vm.fetchAPI(
        "get",
        api ,
      ).then((res)=>{
        console.log(res)
        vm.userInfo.avatar = res.data.memberInfo.avatar
        vm.userInfo.introduction = res.data.memberInfo.introduction
        vm.userInfo.name =  res.data.memberInfo.name
        vm.userInfo.resumeList =  res.data.resumeInfos
        vm.userInfo.followers =   res.data.memberInfo.followerCount
        vm.userInfo.isFollowing =   res.data.memberInfo.isFollowing
        vm.userInfo.postCount  =   res.data.memberInfo.postCount
        vm.userInfo.trackings =   res.data.memberInfo.trackings
        vm.userInfo.isSendRequest = res.data.memberInfo.isSendRequest
        vm.userInfo.isFriend = res.data.memberInfo.isFriend

      })

    
    },
    follower(){
      let vm =this;
      const data ={
        FollowerId:vm.$route.params.Id
      }
      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/Connection/Follower`,data
      ).then((res)=>{
        console.log(res);
        vm.userInfo.isFollowing =  !vm.userInfo.isFollowing
        vm.userInfo.followers  ++;
      })
    },
    unFollower(){
      let vm =this;
      const data ={
        FollowerId:vm.$route.params.Id
      }
      vm.fetchAPI(
        "delete",
        `${process.env.VUE_APP_URL_API}/Connection/UnFollower`,data
      ).then((res)=>{
        console.log(res);
        vm.userInfo.isFollowing =  !vm.userInfo.isFollowing
        vm.userInfo.followers --;
      })
    },
    friendRequest(){
      let vm =this;
      const data ={
        ReceiverId:vm.$route.params.Id
      }
      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/Connection/FriendRequest`,data
      ).then((res)=>{
        console.log(res);
        vm.userInfo.isSendRequest   = !vm.userInfo.isSendRequest 
      })
    },
    unFriendRequest(){
      let vm= this;
      vm.fetchAPI(
        "delete",
        `${process.env.VUE_APP_URL_API}/Connection/UnFriendRequestByUserId/${vm.$route.params.Id}`,
      ).then((res)=>{
        console.log(res)
        vm.userInfo.isSendRequest   = !vm.userInfo.isSendRequest 
        vm.notify("success", `已取消`);

      })
    },
    deleteFriend(){
      let vm= this;
      vm.fetchAPI(
        "delete",
        `${process.env.VUE_APP_URL_API}/Connection/RemoveFriend/${vm.$route.params.Id}`,
      ).then((res)=>{
        console.log(res)
        vm.userInfo.isFriend=! vm.userInfo.isFriend;
        vm.notify("success", `已解除`);
      })
    }
  },
  mounted(){
    let vm =this;
    vm.initData();
  }
};
</script>

<style lang="scss" scoped>
.user-profile {
  text-align: center;
}
.user-name,
.user-Introduction-title {
  margin-top: 10px;
  font-weight: 600;
  font-size: large;
}
.user-Introduction-title,
.user-Introduction {
  text-align: left;
}
.user-Introduction {
  line-height: 1.25;
  white-space: normal; 
  word-wrap: break-word; 
  overflow-wrap: break-word;
  max-width: 100%;

}
.user-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.user-stats {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.user-stat {
  text-align: center;
}

</style>
