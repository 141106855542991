<template>
	<div>
		<div
			class="container container-sm"
			ref="scrollContainer"
			@scroll="checkScroll"
		>
			<div class="row">
				<div class="col-12 col-md-3">
					<div class="left-column">
						<UserResume />
					</div>
				</div>

				<div class="col-12 col-md-6">
					<template
						v-if="
							$route.params.Id == undefined ||
							$route.params.Id == currentUser().id
						"
					>
						<el-card class="mt-3 mt-md-0">
							<el-avatar :src="currentUser().avatar" />
							<p class="user-name mb-1">
								{{ currentUser().name }} 您好，您在找學生嗎?
							</p>
							<p>免費、簡單的方法，快速找到適合的學生</p>
							<div class="mt-2">
								<el-button class="mb-1" type="primary" plain>
									是，加入老師行列</el-button
								>
								<el-button class="mb-1" type="primary" plain
									>目前還沒有</el-button
								>
							</div>
						</el-card>
						<el-card
							class="mt-3 custom-card mb-3"
							:body-style="{
								padding: '0px',
							}"
						>
							<div class="startPost py-3" round @click="redirectToAddPost()">
								<i class="fal fa-edit me-2"></i>
								<span>開始撰寫 貼文</span>
							</div>
							<div class="py-2 articleTip">
								<p>
									<span class="blueFont">撰寫貼文</span>
									發表於喬米部落
								</p>
							</div>
						</el-card>
					</template>
					<template v-if="posts.length != 0">
						<el-divider content-position="right"
							><span class="me-2">排列方式</span>
							<el-dropdown @command="handleCommand" size="small">
								<span class="el-dropdown-link">
									<span>{{ currentSelection }}</span>
									<i class="fas fa-caret-down ms-1"></i>
								</span>
								<template #dropdown>
									<el-dropdown-menu>
										<el-dropdown-item command="hot">熱門</el-dropdown-item>
										<el-dropdown-item command="new">最新</el-dropdown-item>
									</el-dropdown-menu>
								</template>
							</el-dropdown>
						</el-divider>
						<div class="center-column">
							<el-card v-for="post in posts" :key="post.id" class="post">
								<div class="post-header">
									<el-avatar size="" :src="post.author.avatar" />
									<div class="post-authorInfo">
										<span
											class="post-author mb-2"
											@click="goTribe(post.author.id)"
											>{{ post.author.name }}</span
										>
										<span class="post-time">{{ post.createDate }}</span>
									</div>

									<!-- <div class="ms-auto">
                  <el-button type="info" text size="small">
                    <i class="far fa-ellipsis-h"></i
                  ></el-button>
                </div> -->
								</div>
								<div class="post-content">
									<el-tag
										:key="tag"
										class="me-2 mb-3"
										v-for="tag in post.tags"
										:disable-transitions="false"
									>
										#{{ tag.label }}
									</el-tag>
									<div
										class="post-title"
										v-html="sanitizeHTML(post.title)"
									></div>

									<template v-if="post.videoPath != ''">
										<video-player class="video-player w-100" :height="300"
										:options="{
										...playerOptions,
										sources: [
											{
												src: post.videoPath,
												type: 'video/mp4'
											}
										]
									}">
									</video-player>
										
									</template>
									<template v-else>
										<div
											class="ck-content"
											v-html="sanitizeHTML(post.content)"
										></div>
									</template>
								</div>
								<el-button
									class="w-100"
									size="small"
									link
									@click="redirectToViewPost(post.id)"
									>See More...</el-button
								>

								<p class="text-end thumbsAndseens">
									<i
										class="fad fa-eye ms-2"
										style="
											--fa-primary-color: #2474ff;
											--fa-secondary-color: #2474ff;
										"
									></i>
									<span class="seenCount">{{ post.postPreviewCount }}</span>
								</p>
								<div class="LikeShared">
									<p class="d-flex m-0 mt-2">
										<el-button
											v-if="post.isLike"
											class="PostBtn"
											link
											@click="removeLike(post)"
										>
											<i
												class="fa-duotone fa-thumbs-up fw-bold"
												style="
													--fa-primary-color: #4134fe;
													--fa-secondary-color: #fe9b10;
												"
											></i>
											<span class="ms-2" style="font-size: small">{{
												post.likes.length
											}}</span>
										</el-button>
										<el-button
											v-else
											class="PostBtn"
											link
											@click="sendLike(post)"
										>
											<i class="PostBtn fal fa-thumbs-up"></i>
											<span class="ms-2" style="font-size: small">{{
												post.likes.length
											}}</span>
										</el-button>
										<el-button
											class="PostBtn"
											link
											@click="showCommentBox(post)"
										>
											<i class="PostBtn fal fa-comment"></i>
											<span class="ms-2" style="font-size: small">{{
												post.commentCount
											}}</span>
										</el-button>

										<!-- <i class="PostBtn fal fa-share ms-3"></i> -->
									</p>
								</div>
							</el-card>
							<el-button
								v-if="notMore == false"
								@click="loadMorePosts"
								id="load-more-btn"
							>
								載入更多</el-button
							>
						</div>
					</template>
					<el-card v-else class="post"> 尚未發表貼文 </el-card>
				</div>

				<div class="col-12 col-md-3">
					<el-card>
						<img src="https://picsum.photos/200/" />
					</el-card>
				</div>
			</div>
		</div>
	</div>

	<el-drawer
		v-model="commentDraw"
		title="回應"
		:size="$store.getters.mobileSize ? '100%' : '40%'"
	>
		<div>
			<el-card
				style="top: 0px"
				class="position-sticky"
				:body-style="{
					padding: '10px',
				}"
			>
				<div class="d-flex align-items-center mb-2">
					<el-avatar size="small" :src="currentUser().avatar"></el-avatar>
					<span class="ms-2">{{ currentUser().name }}</span>
				</div>
				<el-input
					v-model="textarea"
					maxlength="30"
					placeholder="說些什麼"
					show-word-limit
					type="textarea"
					:autosize="{ minRows: 3, maxRows: 6 }"
					class="mb-2"
				/>
				<el-button
					class="ms-auto"
					type="success"
					round
					:disabled="textarea == ''"
					@click="sendComment()"
					>回應</el-button
				>
			</el-card>

			<div
				v-for="(i, index) in comments"
				:key="i"
				class="my-3 px-2"
				:class="index != 0 ? 'border-top' : ''"
			>
				<div class="d-flex my-2 align-items-center text-start">
					<el-avatar size="small" :src="i.userAvatar"></el-avatar>
					<div class="ms-3">
						<p class="mb-1">{{ i.userName }}</p>
						<p style="color: rgba(0, 0, 0, 0.5); font-size: small">
							{{ i.createTime }}
						</p>
					</div>

					<el-dropdown
						class="ms-auto"
						trigger="click"
						v-if="i.userId == currentUser().id"
					>
						<el-button class="" link>
							<i class="far fa-ellipsis-v"></i>
						</el-button>
						<template #dropdown>
							<el-dropdown-menu>
								<el-dropdown-item type="text" @click="addContactBook"
									>編輯</el-dropdown-item
								>
								<el-dropdown-item type="text" @click="getTemplate">
									<span class="text-danger" @click="opendeleteComfirm(i.id)"
										>刪除</span
									>
								</el-dropdown-item>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</div>
				<div class="text-start mt-2">{{ i.content }}</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import UserResume from "../../components/ChargeMeTribe/HomePage/UserResume.vue";

export default {
	data() {
		return {
			textarea: "",
			postsCount: 100,
			followersCount: 50,
			followingCount: 30,
			posts: [],
			commentDraw: false,
			comments: [],
			showLoadMoreButton: true,
			commentPost: "",
			itemsPerPage: 5,
			pageNumber: 1,
			notMore: false,
			currentSelection: "最新",
			sortType: "new",
			playerOptions: {
        autoplay: false,
        controls: true
      }
		};
	},
	watch: {
		"$route.params.Id": function (newId, oldId) {
			console.log(newId, oldId);
			this.posts = [];
			this.loadData();
		},
	},
	components: { UserResume },
	methods: {
	
		loadData() {
			let vm = this;
			let popularSort = vm.sortType == "hot" ? true : false;
			let api = `${process.env.VUE_APP_URL_API}/Post/GetCurrentPosts/${vm.pageNumber}/${vm.itemsPerPage}/${popularSort}`;
			if (vm.$route.params.Id == undefined) {
				api = `${process.env.VUE_APP_URL_API}/Post/GetPosts/${vm.pageNumber}/${vm.itemsPerPage}/${popularSort}`;
			} else if (vm.$route.params.Id != vm.currentUser().id) {
				api = `${process.env.VUE_APP_URL_API}/Post/GetPosts/${vm.$route.params.Id}/${vm.pageNumber}/${vm.itemsPerPage}/${popularSort}`;
			}
			vm.fetchAPI("GET", api, vm.postInfo)
				.then((res) => {
					vm.posts = [...vm.posts, ...res.data.publishedPosts];
					if (res.data.publishedPosts.length == 0) {
						vm.notMore = true;
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		loadMore() {
			this.loadData();
		},
		goTribe(id) {
			let vm = this;
			vm.$router.push({
				name: "ChargeMeTribe",
				params: {
					Id: id,
				},
			});
		},
		sendLike(post) {
			let vm = this;
			let postId = post.id;
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/Post/Like/Add/${postId}`,
				{},
				false
			).then((res) => {
				console.log(res);
				post.isLike = true;
				post.likes.unshift({
					userId: vm.currentUser().id,
					userAvatar: vm.currentUser().avatar,
				});
			});
		},
		removeLike(post) {
			let vm = this;
			let postId = post.id;
			vm.fetchAPI(
				"delete",
				`${process.env.VUE_APP_URL_API}/Post/Like/Remove/${postId}`,
				{},
				false
			).then((res) => {
				console.log(res);
				post.isLike = false;
				post.likes = post.likes.filter((x) => x.userId != vm.currentUser().id);
			});
		},
		showCommentBox(post) {
			let vm = this;
			vm.fetchAPI(
				"get",
				`${process.env.VUE_APP_URL_API}/Post/Comment/Get/${post.id}`
			)
				.then((res) => {
					vm.comments = res.data;
				})
				.catch((err) => {
					vm.savedTxt = "儲存失敗請稍後再試";
					console.log(err);
				});
			vm.commentPost = post;
			vm.commentDraw = true;
		},
		sendComment() {
			let vm = this;
			const data = {
				postId: vm.commentPost.id,
				content: vm.textarea,
			};
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/Post/Comment/Add`,
				data
			)
				.then((res) => {
					console.log(res.data);
					vm.comments.unshift({
						id: res.data.id,
						content: vm.textarea,
						userId: vm.currentUser().id,
						userName: vm.currentUser().name,
						userAvatar: vm.currentUser().avatar,
						createTime: "剛剛",
					});
					vm.textarea = "";
					vm.commentPost.commentCount++;
				})
				.catch((err) => {
					vm.savedTxt = "儲存失敗請稍後再試";
					console.log(err);
				});
		},
		opendeleteComfirm(id) {
			let vm = this;
			this.$confirm("確定刪除此回應嗎?", "提示", {
				confirmButtonText: "確定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					vm.fetchAPI(
						"delete",
						`${process.env.VUE_APP_URL_API}/Post/Comment/Delete/${id}`,
						{},
						false
					).then((res) => {
						console.log(res);
						vm.comments = vm.comments.filter((x) => x.id != id);
						vm.commentPost.commentCount--;
						this.$message({
							type: "success",
							message: "刪除成功!",
						});
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消",
					});
				});
		},
		checkScroll() {
			// const container = this.$refs.scrollContainer;
			// const scrollPosition = container.scrollTop + container.clientHeight;
			// const bottomPosition = container.scrollHeight;

			// // 當滾動位置接近底部時觸發
			// if (scrollPosition >= bottomPosition - 1) {
			// 	console.log("Reached bottom, loading more posts...");
			// 	this.loadMorePosts();
			// }
		},
		loadMorePosts() {
			let vm = this;
			vm.pageNumber++;
			vm.loadData();
		},
		redirectToAddPost() {
			this.$router.push("/ChargeMeTribe/PostList");
		},
		redirectToViewPost(id) {
			let vm = this;
			vm.$router.push({
				name: "ChargeMeTribe_Post",
				params: { id: id },
			});
		},
		sanitizeHTML(html) {
			return html
				.replace(/(<img\s[^>]*?)\s(width|height)="[^"]*"([^>]*>)/gi, "$1$3")
				.replace(
					/<img\s([^>]*?)\s(width|height)="[^"]*"([^>]*?)>/gi,
					(match, p1, p2, p3) => {
						// 檢查是否已經有 style 屬性
						const hasStyle = /style="[^"]*"/.test(p1);
						if (hasStyle) {
							// 如果有 style 屬性，則添加 width: 100%
							return `<img ${p1.replace(
								/style="([^"]*)"/,
								(match, styleContent) => `style="${styleContent}; width: 100%"`
							)} ${p3}>`;
						} else {
							// 如果沒有 style 屬性，則創建 style 屬性
							return `<img ${p1} style="width: 100%; height: auto; aspect-ratio:1074/717;" ${p3}>`;
						}
					}
				);
		},
		handleCommand(command) {
			this.sortType = command;
			if (command === "hot") {
				this.currentSelection = "熱門";
			} else if (command === "new") {
				this.currentSelection = "最新";
			}
			this.posts = [];

			this.loadData();
		},
	},
	created() {
		this.pageNumber = 1;
	},
	mounted() {
		this.loadData();
		const scrollContainer = this.$refs.scrollContainer;
		scrollContainer.addEventListener("scroll", this.checkScroll());
	},
};
</script>

<style scoped>
body {
	font-family: Arial, sans-serif;
	margin: 0;
	padding: 0;
	background-color: #f2f2f2;
	color: #333;
}

header {
	background-color: #333;
	color: #fff;
	padding: 10px;
	text-align: center;
}

.container {
	margin: 20px auto;
	width: 100%;
}

.left-column,
.center-column,
.right-column {
	display: flex;
	flex-direction: column;
}

.left-column,
.right-column {
	flex-grow: 1;
	/* background-color: #f9f9f9; */
	/* border-right: 1px solid #ddd; */
}

.center-column {
	overflow-y: auto;
	flex-grow: 2;
	/* max-height: 500px; 控制中央區域的最大高度 */
}

h2 {
	color: #333;
}

.post {
	margin-bottom: 20px;
}

.post-header {
	display: flex;

	margin-bottom: 10px;
}
.post-authorInfo {
	padding: 0.25rem;
	margin-left: 1rem;
	display: flex;
	flex-direction: column;
	text-align: start;
}

.startPost {
	font-size: large;
	color: #8c8c8c;
}
.LikeShared {
	text-align: start;
	color: #8c8c8c;
}
.startPost:hover {
	cursor: pointer;
	background: rgba(0, 0, 0, 0.1);
}
.PostBtn:hover {
	cursor: pointer;
	color: #0077b5;
}
.PostBtn {
	font-size: large;
}
.post-author {
	font-weight: bold;
	text-decoration: underline;
}
.articleTip {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	background-color: #f3f6f8;
}
.post-subscriptions,
.post-time {
	font-size: small;
	color: rgba(0, 0, 0, 0.5);
}

.post-content {
	margin-top: 1rem;
	margin-bottom: 1em;
	text-align: start;
	line-height: 1.25;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 5; /* 显示的行数 */
}
.post-title {
	margin-left: 0;
	font-size: x-large;
	font-weight: 700;
	margin-bottom: 10px;
}
.thumbsAndseens {
	margin-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.thumbsCount,
.seenCount {
	font-size: small;
	margin-left: 0.25rem;
	color: rgba(0, 0, 0, 0.5);
}
.blueFont {
	color: #0077b5;
	font-weight: 600;
}
footer {
	background-color: #333;
	color: #fff;
	text-align: center;
	padding: 10px;
}

.el-card {
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* 自定义阴影效果 */
}

/* 滾動條寬度 */
::-webkit-scrollbar {
	width: 12px;
}

/* 滾動條軌道 */
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 10px;
}

/* 滾動滑塊 */
::-webkit-scrollbar-thumb {
	background: #cacaca;
	border-radius: 10px;
}

/* 滾動滑塊在懸停時的樣式 */
::-webkit-scrollbar-thumb:hover {
	background: #888;
}
</style>
